const faqs = [
  {
    question: "Pasaporte válido:",
    answer: (
      <div>
        <p>Todos los viajeros que quieran ingresar a Italia desde cualquier país del mundo necesitan un pasaporte válido. Este debe tener una vigencia de al menos seis meses desde la fecha prevista de salida del país.En caso de que tu pasaporte se haya vencido, te recomendamos que visites el siguiente link <a href='https://assist-365.com/ar/tramites/como-renovar-el-pasaporte-italiano' className='underline text-blue-500'>  ¿Cómo renovar mi pasaporte Italiano?  </a>Además, algunos países necesitan una visa, por lo que es importante verificar si se necesita una antes de viajar.
        
        </p>
      </div>
    ),
  },
  {
    question: "Visa Schengen:",
    answer: (
      <div>
        <p>Si sos ciudadano argentino, no necesitás un visado Schengen para ingresar a Italia por un período de estancia de hasta 90 días. Sin embargo, si planeas quedarte en Italia por más de 90 días, debes solicitar una visa de larga duración.
 </p> 

      </div>
    ),
  },
  {
    question: "Prueba de vacunación o prueba PCR negativa:",
    answer: (
      <div>
        <p>
        Los visitantes que lleguen a Italia desde Argentina deben presentar una prueba PCR negativa realizada en las 72 horas previas a la llegada. Esta prueba debe ser en inglés o italiano, y se debe proporcionar una copia impresa o electrónica del resultado de la prueba.
        </p> 
      </div>
    ),
  },
  {
    question: "Seguro de viaje:",
    answer: (
      <div>
        <p>
        Se recomienda que contraten un <a href='https://assist-365.com/ar/destinos/seguro-de-viaje-europa' className='underline text-blue-500'>  seguro de viaje a europa </a> que cubra gastos médicos, repatriación y cancelación de viaje. Este seguro puede ser requerido en algunas circunstancias. </p>
      </div>
    ),
  },
  {
    question: "Reservas de alojamiento: ",
    answer: (
      <div>
        <p>
        Los viajeros que llegan a Italia deben presentar una reserva de alojamiento válida en el país durante todo el tiempo de su estancia. Esto puede ser una reserva de hotel, un contrato de arrendamiento o una carta de invitación de un residente italiano. </p>
      </div>
    ),
  },
  {
    question: "Documentación de respaldo:",
    answer: (
      <div>
        <p>
        En algunos casos, se puede solicitar a los viajeros que proporcionen documentación adicional, como una carta de empleador, extractos bancarios o una carta de invitación de un residente italiano. Esta documentación debe ser presentada en el momento de la solicitud de la visa o a la llegada al país.</p>
      </div>
    ),
  },
  {
    question: "Restricciones COVID-19:",
    answer: (
      <div>
        <p>
        Debido a la pandemia, se han implementado restricciones de viaje en Italia y pueden variar según la situación actual del país. Se recomienda revisar regularmente las restricciones vigentes antes de viajar y estar preparado para cambios de última hora. </p>
      </div>
    ),
  },

];

export default faqs;
